/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// SCREEN WIDTH 1450px - START

@media screen and (min-width: 1450px)
	div.DivTableContainer
		ul.ListTableConversation
			li
				div.DivConversationGeneral
					div.DivConversationHeader
						div.DivConversationSubHeader
							span.SpanConversationTitle
								max-width: initial !important

// SCREEN WIDTH 1450px - END


// SCREEN WIDTH 1424px - START

@media screen and (max-width: 1424px)
	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationHeader
					div.DivConversationProfile
						display: none

// SCREEN WIDTH 1424px - END


// SCREEN WIDTH 1330px - START

@media screen and (max-width: 1330px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			flex-direction: column
			div.DivContainer
				margin-top: $SPACE_SMALL
				margin-right: 0px
				margin-left: 0px
				margin-bottom: $SPACE_SMALL
			div.DivFormVerticalContainer
				flex-direction: row
				justify-content: flex-start

// SCREEN WIDTH 1330px - END


// SCREEN WIDTH 1250px - START

@media screen and (max-width: 1250px)
	div#divBodyDashboard1
		div.DivContentDashboard
			div.DivContentSection
				div.DivContentTitle
					h3
						font-size: 3em


// SCREEN WIDTH 1250px - END


/* SCREEN WIDTH 1140px - START */

@media screen and (max-width: 1140px)
	div#divBodyHome main
		padding: $SPACE_SMALL

	div.DivVerifierGeneral, div.CenterGeneral
		margin: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader, input[type=button]#buttonNavigation
		top: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader
		right: $SPACE_SMALL

	input[type=button]#buttonNavigation
		left: $SPACE_SMALL

	div.DivMainAsideSearch
		flex-direction: column
		form
			width: 100%
		div.DivDynamicContainer
			margin-left: 0px
			div.DivSubDynamicContainer
				margin-top: 0px

	div.DivContainerListHorizontal
		div.DivListContainer
			form
				div.DivForm
					fieldset
						div.DivFormListHorizontalContainer
							padding-left: $SPACE_LITTLE
							flex-direction: column
							div.DivForm2Column
								width: 100%
							div.DivRoundButtonContainer
								display: none
				input[type=button]#inputButtonSubmit
					display: block

	div.DivForm
		padding-left: $SPACE_SMALL
		padding-right: $SPACE_SMALL

	div.DivTableContainer
		margin-left: 0px

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 100px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 100px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationHeader
					div.DivConversationProfile
						display: block

// SCREEN WIDTH 1140px - END


// SCREEN WIDTH 950px - START

@media screen and (max-width: 950px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			div.DivFormVerticalContainer, div.DivFormHorizontalContainer
				flex-direction: column
				align-content: center
				align-items: center

	form div.DivForm fieldset, div.DivForm
		div.DivFormVerticalContainer
			flex-direction: column
			div.DivForm2Column
				margin-left: 0px
				margin-right: 0px

	form.CalculateForm
		width: 100%
		div.DivForm
			fieldset
				div.DivFormHorizontalContainer
					div.DivForm2Column
						width: 100%
		input[type=button].ButtonRight
			width: 100%

	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationStatus
					flex-direction: column
					flex-shrink: 1
				div.DivConversationHeader
					flex-direction: column
					align-items: flex-end
					div.DivConversationProfile
						display: none
			table.TableListDetails
				tbody
					tr
						td
							display: table-cell
						td:nth-child(2), td:nth-child(3)
							width: auto
						td:nth-child(3)
							display: none
	div#divMainDashboard div.DivSiteContainer
		margin: $SPACE_BIG
	div#divMainProductContainer
		section.SectionSecondary, section#sectionHeaderProduct
			height: auto
			div.DivFormVerticalContainer
				flex-direction: column
				div
					width: auto !important
				div.DivProduct
					display: flex
					margin: auto
					h2
						margin: auto $SPACE_LITTLE

// SCREEN WIDTH 950px - END


// SCREEN WIDTH 800px - START

@media screen and (max-width: 800px)
	div.DivBodyGeneral
		scroll-snap-align: unset
		position: relative

	div#divMainDashboard,
		div.DivModal
			div.DivModalHeader
				h2
					font-size: 1.3em
			div.DivModalFigureContainer
				height: 100%
				margin: 10px
				figure
					width: auto
					height: auto
				iframe
					height: 220px

	div#divBodyDashboard1
		div.DivContentDashboard
			div.DivContentSection
				margin-top: 100px
				margin-bottom: 20px
				margin-left: 20px
				margin-right: 20px
				div.DivContentTitle
					h3
						font-size: 2em

	div#divBodyDashboardBussinesPillars
		div.DivContentLayoutContent
			div.DivContentCorporate
				margin: 100px 20px 20px 20px
				div.DivContentCorporateTitle
					h1
						text-align: center
			div.DivContentCorporateDescription
				margin: 0px
				p
					font-size: 12pt
		div.DivContentLayoutSide
			display: inline-flex
			justify-content: center
			flex-direction: column
			span
				margin: 2px 0
			span.active
				width: 12px
				height: 45px
		div.DivContentCorporateDescription
			margin: 0px 50px
			max-width: 100%
			margin-bottom: 50px

	div#divBodyDashboardAward
		div.DivContentAwardsTitle
			margin: 100px 0px 0px 0px
			height: 50px
		div.DivContentAwardsContent
			flex-direction: column
			align-items: center
			justify-content: space-between
			hr
				display: none
				width: 0px
			div.DivVerticalLine
				display: block
				border-left: 6px solid  green
				position: absolute
			div.DivContentCalenderDetail
				top: 0px
				margin-top: 150px
				width: -webkit-fill-available

	div#divBodyDashboardBRINSUpdate
		div.DivContentCorporateSlider
			margin-top: 20px
			margin-bottom: 20px
			margin-left: 20px
			margin-right: 20px
			display: flex
			flex-direction: column
			justify-content: space-between
			div.DivContentCorporateTitle
				display: flex
				flex-direction: column
				height: 100%
				align-items: center
				div.DivContentTextDetail
					width: 100%
					p
						text-align: center
			div.DivContentImageCorousel
				margin-top: 20px
				padding: 0px
				section#sectionImageCorousel
					width: 30vh
					height: 30vh
			div.DivContentDescription
				display: flex
				flex-direction: column
				padding: 0px
				h1
					text-align: center
					font-size: 1.2em
				p
					font-size: 1em

	div#divBodyDashboardAboutBRINS
		div.DivContent
			margin-top: 100px
			margin-bottom: 20px
			margin-left: 20px
			margin-right: 20px
			div.DivContentTitle
				display: flex
				flex-direction: column
				margin-bottom: 0px
				hr
					margin: 20px 0px 20px 0px
					height: 0px
					background: #FFFFFF
			div.DivContentDescription
				height: unset
				padding-bottom: 20px
			div.DivContentButtonDetail
				text-align: left
				margin-top: 20px
				margin-left: 0px
		h1
			font-size: $FONT_LABEL_H3
			font-weight: 600
			margin-right: 0px
			margin-left: 0px
			letter-spacing: 5px
			width: 100%
		hr
			width: 100%
		p
			font-size: 1.75em
			text-align: justify-all
			margin-left: 0px
			height: 100%

	div#divBodyDashboardPartners
		div.DivContentPartners
			margin-top: 50px
			margin-left: 20px
			margin-right: 20px
			margin-bottom: 20px
			height: 100%
			div.DivContent2Column
				display: flex
				margin: 20px
				height: 100%
				align-items: center
				flex-direction: column
				div.DivContent
					margin: 0px
					h2
						text-align: center
					p
						text-align: left
					img
						display: none
			div.DivContent:nth-child(odd)
				width: 100%
			div.DivContent:nth-child(even)
				width: 100%
			div.DivContentShapeDescription
				display: none
			img#imageIconPartners
				display: none
		div.DivContent
			margin-top: 0px
			margin-left: 20px
			margin-right: 20px
			margin-bottom: 0px
			h1
				margin-top: 0px
			div.DivContentImageGallery
				div.DivButtonNavigate
					img
						width: 10px
						height: 10px
						padding: 15px
				section#sectionImageGallery, section#sectionImageGalleryBroker
					label.labelImagePrevious, label.labelImageNext
						display: none
					label.labelImageActive
						img
							width: 200px

	// ABOUT - START

	div#divBodyAbout
		margin-bottom: 0px
		div.DivContentMain
			display: flex
			height: 100vh
			width: auto
			margin-bottom: 0px
			flex-direction: column
			div.DivContentAbout
				display: flex
				flex-direction: column
				margin-top: 100px
				margin-right: 20px
				margin-left: 20px
				margin-bottom: 20px
				width: auto
				height: 100%
				div.DivHeaderAbout, div.DivAboutHeaderTitle
					text-align: center
			div.DivContentDescription
				width: 100%
				margin-top: 0px
				margin-bottom: 0px
				p
					text-align: center
			div.DivContentButtonAbout
				div.DivContentButtonDetail
					a.ButtonForward
						left: 0px
			h1
				font-size: 2.5em
			p
				font-size: 1.2em
				font-weight: 550
		div.DivContentImage
			display: flex
			position: relative
			width: 100%
			height: 100%
			flex-direction: column
			align-items: center
			img:nth-of-type(1)
				position: inherit
				left: 0px
				top: 0px
				height: 250px
				width: 300px
			img:nth-of-type(2), img:nth-of-type(3), img:nth-of-type(4), img:nth-of-type(5)
				width: 40px
				height: 40px
			img:nth-of-type(2)
				position: absolute
				left: 27px
				top: 186px
			img:nth-of-type(3)
				position: absolute
				right: 26px
				top: 175px
			img:nth-of-type(4)
				position: absolute
				right: 28px
				top: 30px
			img:nth-of-type(5)
				position: absolute
				left: 30px
				top: 30px
		div#divContentWhatBRINS
			display: flex
			flex-direction: column
			height: 100vh
			div.DivContentAbout
				display: flex
				margin-top: 20px
				margin-right: 20px
				margin-left: 20px
				margin-bottom: 20px
				height: 100%
				flex-direction: column
				p.DivContentSectionInactive
					display: none
				p.DivContentSectionActive
					display: block
				p
					height: 100%
					font-size: 15pt
					text-align: center
				h1
					font-size: 2.8em
		div#divBodyMissionAndVission
			height: 100vh
			div.DivContentColumn
				display: flex
				flex-direction: column
				margin: 20px
				background-color: red
				align-items: center
				div.DivContentImage
					img
						width: 35vh
						object-fit: contain
					img.ImageShapeCEO
						position: absolute
						z-index: -1
						width: 100%
						height: 100%

	// ABOUT - END

	// START - FOOTER

	div#divMainDashboard
		div#divBodyFooterContainer
			height: 100%
			scroll-snap-align: unset
			div.DivFooterInfoPage
				display: flex
				flex-direction: column
				justify-content: unset
				padding-top: 20px
				padding-left: 20px
				padding-right: 20px
				padding-bottom: 100px
				align-items: center
				height: auto
				div.DivFooterVerticalContainer
					width: 100%
					display: flex
					flex-direction: column
					align-items: center
					label
						display: block
						text-align: center
					p
						text-align: center
					div.DivFooterContentContainer
						div.DivFooterSVGContainer
							display: none
					div.DivFooterHorizontalContainer
						display: flex
						justify-content: center
					div.DivFooterSocialMedia
						margin-top: 20px
						margin-bottom: 20px
						div.DivFooterHorizontalContainer
							justify-content: flex-start
							margin: auto
							div.DivFooterSVGContainer
								width: 60px
								img
									display: block
									width: 30px
									height: 30px
									margin: 10px 20px
				div.DivFooterPageHorizontalContainer
					div.DivFooterSectionPage
						display: flex
						flex-direction: column
						div#divFooterSubscribeContainer
							width: 100%
							margin-bottom: 10px
							margin-top: 10px
							div.DivFooterVerticalContainer
								width: auto
								div.DivFormSubscribeEmail
									width: 100%
									font-size: 90%
			div.DivFooterCopyright
				display: none
			div.DivFooterCopyrightResponsive
				display: flex

	// END - FOOTER

// SCREEN WIDTH 800px - END


// SCREEN WIDTH 800px - BRINS WEBSITE - START

@media screen and (max-width: 800px)
	body
		font-size: 90%
		div#divCurtainLoading
			div#divLoadingMessage
				img#imageLoadingCircle
					margin: 0
		div#divMainLandingContainer
			section.SectionMain
				position: relative
				background-position-x: center
				height: 100vh
				// height: 44.3vh
				background-position-x: 60%
				img.ImgShapeMain1
					width: 125%
					margin-left: -30%
				img.ImgShapeMain2
					height: 15%
				div#divMainLanding
					flex-wrap: nowrap
					flex-direction: column
					margin-left: 30px
					margin-right: 30px
					div.DivHeaderCenterContainer
						margin-top: 30%
						justify-content: flex-end
						height: max-content
						flex-grow: 3
						flex-shrink: 1
						flex-basis: auto
						div.DivHeaderContentContainer
							box-sizing: border-box
							padding: 10px 15px
							border-radius: 25px
							width: 80%
						h1
							margin: 0
							line-height: $FONT_PARAGRAPH_LINE_HEIGHT_BIG
						p
							width: 90% !important
							margin: 0
							margin-top: 20px
					div.DivSocialMediaCenterContainer
						height: 30px
						flex-grow: 1
						flex-shrink: 2
						flex-basis: auto
						div.DivSocialMedia
							flex-direction: row
							width: 60%
							align-items: flex-start
							margin-left: 0
							img
								width: 25px
								height: 25px
								&:first-of-type
									margin-left: 0
				div.DivFormHorizontalContainer
					div.DivForm2Column
						width: 100%
			section.SectionSecondary
				div#divSiteLandingContainer
					margin: 20px 40px
					margin-bottom: 30px
					div.DivFirstParagraph
						flex-direction: column-reverse
						div.DivFirstParagraphText
							width: 100%
						div.DivFirstParagraphImage
							width: 100%
							margin-bottom: 20px
							img
								width: 100%
					div.DivHeaderProductLanding
						flex-direction: column-reverse
						height: 100%
						border-bottom-left-radius: 15px
						border-bottom-right-radius: 15px
						div.DivHeaderProductDescription
							padding: 30px
							width: 100%
							height: 270px
							div.DivProductContent
								flex-wrap: nowrap
						div.DivHeaderProductImageDescription
							width: 100%
							height: 300px
							border-top-left-radius: 15px
							border-top-right-radius: 15px
					div.DivFormHorizontalContainer
						flex-direction: column
						div.DivProductDescription
							height: 400px
							padding: 30px
						div.DivProductLanding, div.DivFooterProductLanding
							width: 100%
							border-radius: 15px
							div.DivProductImageDescription
								width: 50%
								border-top-left-radius: 15px
								border-bottom-left-radius: 15px
								img
									width: 50vw
							div.DivProductContent
								flex-wrap: nowrap
						div.DivFooterProductLanding
							margin-bottom: 10px
							border-top-right-radius: 15px
							border-bottom-right-radius: 15px
		div#divMainSectionContainer
			section#sectionHeaderProduct
				height: calc((100vh/3)*2)
				background-position-x: 60%
				img.ImgShapeMain1
					width: 100%
					margin-left: -24%
				img.ImgShapeMain2
					height: 25%
				div.DivSiteContainer
					flex-direction: row
					align-content: flex-end
					div.DivProduct
						width: 100%
						margin-bottom: 15%
						h2
							line-height: 80px
							font-size: 4em
						h3
							font-size: 3em
							width: 50%
						h3 > div.DivProduct
							margin: 0
						h2,h3
							text-align: left
							margin: 0
							box-sizing: border-box
							padding: 10px
							border-radius: 15px
							width: 60%
							white-space: pre-wrap
			section.SectionSecondary
				div.DivSiteContainer
					margin: 40px
					h1
						font-size: 1.8em
					div.DivContentImageGallery
						padding: 0
						height: 200px
						div.DivButtonNavigate
							width: 25px
							height: 25px
							img
								width: 10px
								height: 10px
								padding: 10px
								border-radius: 8px
						section#sectionImageGallery, section#sectionImageGalleryBroker
							height: 130px
							label.LabelImageNonActive
								margin-top: 0
								width: 140px
								img
									width: 130px
									margin: 0 auto
								&:first-of-type, &:last-of-type
									display: none
								p
									width: 80px
									font-size: 0.6em
					div#divProductDetailContainer
						flex-direction: column
						div.DivIconProduct
							margin: 50px auto
							width: 200px
							height: 200px
							img
								width: 200px 
								height: 200px
						div.DivDescriptionDetailContainer
							h2
								font-size: 2.5em
							p
								line-height: 28px
							bottom.ButtonMarginBottom
								margin-bottom: 30px
					div.DivFormHorizontalContainer
						button.ButtonMore
							padding: 10px 30px
							border-radius: 30px
							border: solid 4px #F37024
							margin-top: 20px
				div#divSiteProductList
					grid-template-columns: repeat(2, 1fr)
					grid-gap: 20px
					div#divContentProductList
						height: 360px !important
						font-size: 90%
						flex-basis: 0
						padding: 20px
						margin: 0
						margin-bottom: 20px
						div.DivTitleContentHeader
							img
								margin: 10px
								margin-left: 5px
							label
								font-size: 1em
						p
							line-height: 24px
							margin: 0
							margin-top: 10px
						button.ButtonSubmit
							font-size: 0.8em
							height: 32px
							left: 25px
							right: 25px
							padding: 5px 10px
							border-radius: 10px

// SCREEN WIDTH 800px - BRINS WEBSITE - END


// SCREEN WIDTH 640px - START

@media screen and (min-width: 641px)
	div#divNotificationInstall
		width: 420px
		height: auto
		right: auto
		position: fixed
		left: $SPACE_MEDIUM
		bottom: $SPACE_MEDIUM
		+setBorderRadius($RADIUS_INPUT_GENERAL)

@media screen and (max-width: 640px)
	section
		div.DivFormHorizontalContainer
			flex-direction: column
			align-content: center
			align-items: center
		div.DivFormHorizontalContainer
			flex-direction: column
			div.DivForm2Column
				margin-left: 0px
				margin-right: 0px
				width: 100%
			div.DivForm2ColumnNoMargin:last-of-type:nth-of-type(odd)
				div.DivImageCapture
					width: 100%

	div.DivForm, form div.DivForm fieldset
		.mat-form-field
			width: 100%

	div.DivDynamicContainer
		div.DivConversationHeader
			div.DivConversationProfile
				display: none

	div.DivTableContainer
		ul.ListTableTicket
			img
				display: none
			div.DivTicketItem
				div.DivTicketSubItem:last-of-type
					display: flex
					flex-direction: column
				div.DivTicketTail
					display: flex
					flex-shrink: 1
			li
				padding-left: $SPACE_SMALL
				padding-right: $SPACE_SMALL
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				justify-content: center
				width: 100%
		div.DivTableControl
			flex-direction: column
			div.DivTableSubControl
				margin-top: $SPACE_TINY
				margin-bottom: $SPACE_TINY
		table
			margin-top: $SPACE_SMALL
			margin-bottom: $SPACE_SMALL
			thead
				tr
					th
						padding: $SPACE_THIN
						display: block
					th:first-of-type
						padding-left: 0px
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
						+setBorderRadiusBottom(0px)
					th:last-of-type
						padding-right: 0px
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
				tr:first-of-type
					th
						padding-top: $SPACE_TINY
				tr:last-of-type
					th
						padding-bottom: $SPACE_TINY
			tbody, tfoot
				tr
					td
						padding: $SPACE_THIN
						display: block
						+setBorderRadius(0px)
					td:first-of-type
						padding-top: $SPACE_TINY
						padding-left: 0px
					td:last-of-type
						padding-right: 0px
						padding-bottom: $SPACE_TINY
				tr:first-of-type
					td:first-of-type
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
					td:last-of-type
						+setBorderRadiusTop(0px)
				tr:last-of-type
					td:first-of-type
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom(0px)
					td:last-of-type
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
			th, td
				text-align: center

	main.MainShow
		+setSlideTransform(0, 0px, 0px, 1.0, 1.0, 1.0)
		+setTransition(all, 0.5, ease-in)
	main.MainHide
		+setSlideTransform(-60, 180px, -180px, 0.6, 0.6, 0.6)
		+setTransition(all, 0.5, ease-out)
	div#divBodyHomeMenu
		nav#navigationMainHome
			top: 10px
			left: 10px
			right: 10px
			img#imageLogoHeader
				height: 25px
				margin: auto 15px

	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

	#divMainDashboard
		div.DivFormCardInside
			position: relative 
			margin: $SPACE_MEDIUM
			width: 100%
			z-index: 1
			margin-left: 0px
			div.DivFormBranch
				margin-bottom: $SPACE_BIG
				div.DivBranchCardInformation
					img
						border-radius: 50%
						padding: $SPACE_TINY
				input[type=button].ButtonShare
					background-position-x: 170px
				p
					margin-right: 200px
		div.DivFormNoBorder
			google-map
				.map-container
					border-radius: $SPACE_SMALL


	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

// SCREEN WIDTH 640px - END


// SCREEN WIDTH 490px - START

@media screen and (max-width: 490px)
	ul.ListTableConversation
		li.LiConversationContainerRight, li.LiConversationContainerLeft
			div.DivConversationGeneral
				div.DivConversationItem
					flex-direction: column
					align-items: flex-start
					div.DivConversationHeader
						margin-top: $SPACE_LITTLE
						div.DivConversationSubHeader
							span
								text-align: left !important
							span.SpanConversationTitle
								max-width: initial !important
	div.DivSiteContainer
		margin: 0px 20px !important
	body
		div#divMainSectionContainer
			section.SectionSecondary
				div.DivSiteContainer
					div#divProductDetailContainer
						div.DivIconProduct
							width: 150px
							height: 150px
						div.DivDescriptionDetailContainer
							p
								line-height: 24px

// SCREEN WIDTH 490px - END


// SCREEN WIDTH 428px - START

@media screen and (max-width: 428px)
	body
		font-size: 80%
		div#divCurtainLoading
			div#divLoadingMessage
				img#imageLoadingCircle
					margin: 0
		div#divMainLandingContainer
			section.SectionMain
				position: relative
				background-position-x: 60%
				height: 100vh
				img.ImgShapeMain1
					width: 125%
					margin-left: -28%
				img.ImgShapeMain2
					height: 10%
				div#divMainLanding
					flex-wrap: nowrap
					flex-direction: column
					margin-left: 30px
					margin-right: 30px
					div.DivHeaderCenterContainer
						margin-top: 90%
						margin-right: 10%
						justify-content: flex-end
						height: max-content
						flex-grow: 3
						flex-shrink: 1
						flex-basis: auto
						div.DivHeaderContentContainer
							font-size: 70%
							box-sizing: border-box
							padding: 10px 15px
							border-radius: 25px
							width: 80%
						h1
							margin: 0
							line-height: 40px
						p
							margin: 0
							width: 90% !important
							margin-top: 5px
							font-size: 1.2em !important
					div.DivSocialMediaCenterContainer
						height: 30px
						flex-grow: 1
						flex-shrink: 2
						flex-basis: auto
						div.DivSocialMedia
							flex-direction: row
							width: 62%
							align-items: flex-start
							margin-left: 0
							img
								width: 18px
								height: 18px
								&:first-of-type
									margin-left: 0
				div.DivFormHorizontalContainer
					div.DivForm2Column
						width: 100%
			section.SectionSecondary
				div#divSiteLandingContainer
					div.DivFirstParagraph
						flex-direction: column-reverse
						div.DivFirstParagraphText
							width: 100%
						div.DivFirstParagraphImage
							width: 100%
							margin-bottom: 20px
							img
								width: 100%
								border-radius: 20px
					div.DivHeaderProductLanding
						flex-direction: column-reverse
						height: 100%
						border-bottom-left-radius: 15px
						border-bottom-right-radius: 15px
						div.DivHeaderProductDescription
							width: 100%
							height: 300px
							padding: 20px
							div.DivProductContent
								flex-wrap: nowrap
						div.DivHeaderProductImageDescription
							width: 100%
							height: 180px
							border-top-left-radius: 15px
							border-top-right-radius: 15px
					div.DivFormHorizontalContainer
						flex-direction: column
						div.DivProductDescription
							height: 250px
							padding: 20px
						div.DivProductLanding, div.DivFooterProductLanding
							flex-direction: column
							width: 100%
							border-radius: 15px
							div.DivProductImageDescription
								width: 100%
								border-top-left-radius: 15px
								border-top-right-radius: 15px
								border-bottom-left-radius: unset
								border-bottom-right-radius: unset
								background-position-y: 55%
								img
									height: 180px
									width: 100%
							div.DivProductContent
								flex-wrap: nowrap
						div.DivFooterProductLanding
							margin-bottom: 10px
		div#divMainSectionContainer
			section#sectionHeaderProduct
				height: calc((100vh/3)*2)
				background-position-x: 60%
				img.ImgShapeMain1
					width: 100%
					margin-left: -24%
				img.ImgShapeMain2
					height: 25%
				div.DivSiteContainer
					flex-direction: row
					align-content: flex-end
					div.DivProduct
						margin: 0 0 20% 0
						width: 100%
						h2
							font-size: 2.8em
							text-align: left
							line-height: 80px
							margin: 0
							// background-color: rgba(29, 86, 52, 0.8)
							box-sizing: border-box
							padding: 10px 15px
							border-radius: 15px
							width: 80%
						h3
							font-size: 2em
							width: 58%
			section.SectionSecondary
				div.DivSiteContainer
					h1
						font-size: 2.5em
						margin-top: 30px
						margin-bottom: 30px
					div.DivContentImageGallery
						padding: 0
						height: auto
						margin-bottom: 40px
						div.DivButtonNavigate
							width: 30px
							height: 25px
							img
								width: 10px
								height: 10px
								padding: 10px
								border-radius: 8px
						section#sectionImageGallery, section#sectionImageGalleryBroker
							height: 130px
							label.LabelImageNonActive
								margin-top: 0
								width: 140px
								justify-content: space-evenly
								img
									width: 140px
									margin: 0 auto
								&:first-of-type, &:nth-child(2), &:nth-child(4), &:last-of-type
									display: none
								p
									width: max-content
									font-size: 1em
					div#divProductDetailContainer
						div.DivDescriptionDetailContainer
							h2
								font-size: 1.8em
							button.ButtonMarginBottom
								margin-bottom: 20px
					div.DivFormHorizontalContainer
						button.ButtonMore
							padding: 10px 30px
							border-radius: 30px
							border: solid 4px #F37024
							margin-bottom: 40px
				div#divSiteProductList
					grid-template-columns: repeat(2, 1fr)
					grid-gap: 10px
					div#divContentProductList
						height: 300px !important
						font-size: 90%
						flex-basis: 0
						padding: 10px
						margin: 0
						margin-bottom: 35px
						div.DivTitleContentHeader
							img
								width: 40px
								height: 40px
								margin: 5px
								margin-left: 5px
							label
								font-size: 1em
						p
							line-height: 21px
							margin: 0
							margin-top: 10px
							margin-left: 5px
						button.ButtonSubmit
							font-size: 0.8em
							height: 32px
							left: 25px
							right: 25px
							padding: 5px 10px
							border-radius: 10px

div#divMainDashboard
	h1
		line-height: calc($FONT_PARAGRAPH_LINE_HEIGHT_BIG - 20px)
	h2
		line-height: calc($FONT_PARAGRAPH_LINE_HEIGHT - 8px)

// SCREEN WIDTH 428px - END


// SCREEN WIDTH 375px - START

@media screen and (max-width: 376px)
	body
		font-size: 75%
		div#divMainSectionContainer
			section#sectionHeaderProduct
				div.DivSiteContainer
					div.DivProduct
						h3
							margin-bottom: -10%
		div#divCurtainLoading
			div#divLoadingMessage
				img#imageLoadingCircle
					margin: 0
		div#divMainLandingContainer
			section.SectionMain
				position: relative
				background-position-x: 60%
				height: 100vh
				img.ImgShapeMain1
					width: 125%
					margin-left: -28%
				img.ImgShapeMain2
					height: 10%
				div#divMainLanding
					flex-wrap: nowrap
					flex-direction: column
					margin-left: 30px
					margin-right: 30px
					div.DivHeaderCenterContainer
						margin-top: 125%
						margin-right: 10%
						justify-content: flex-end
						height: max-content
						flex-grow: 3
						flex-shrink: 1
						flex-basis: auto
						div.DivHeaderContentContainer
							font-size: 70%
							box-sizing: border-box
							padding: 10px 15px
							border-radius: 25px
							width: 80%
						h1
							margin: 0
							line-height: 40px
						p
							margin: 0
							width: 100% !important
							margin-top: 5px
							font-size: 1.2em !important

// SCREEN WIDTH 375px - END